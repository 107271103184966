import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import ReactRotatingText from 'react-rotating-text';
import styled from 'styled-components';

// component
import Layout from '../components/layout';
import Seo from '../components/seo';
import MiniHeader from '../components/mini-header';

// img
import headerBackground from '../images/commit/commit-header.jpg';

// style-component
const Container = styled.div`
  max-width: 90%;
  width: 980px;
  margin: 0 auto;
`;

const CommitArticle = styled.article`
  padding: 30px 0;
`;

const CommitArticleP = styled.article`
  padding: 30px 0;
  padding: 0 1rem;
`;

const CommiIntroP = styled.p`
  margin-top: 20px;
  margin-bottom: 40px;
`;

const CommitIntroTitle = styled.span`
  color: #000;
  font-size: 21px;
  text-transform: uppercase;
  font-weight: 600;
`;

const CommitIntroIitleHigh = styled.span`
  color: #ffd700;
`;

const CommitTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.4fr 1fr;
  align-items: center;
`;

const CommitTitleText = styled.div`
  text-align: center;
  text-transform: uppercase;
  color: #999999;
  font-size: 22px;
  line-height: 1;
`;

const CommitTitleSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: #999999;
`;

const CommitPerformance = styled.div`
  padding-top: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 205px 205px 205px;
  gap: 15px 0px;

  @media only screen and (max-width: 768px) {
    padding-top: 40px;
    grid-template-columns: 1fr;
    grid-template-rows: 205px 205px 205px;
    gap: 15px 0px;
  }
  @media only screen and (max-width: 424px) {
    padding-top: 20px;
  }
`;

const CommitCard = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 0.2fr;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 0.7fr 0.3fr !important;
  }
`;

const CommitCardRight = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 0.8fr;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 0.3fr 0.7fr !important;
  }
`;

const CommitCardImage = styled.div`
  text-align: right;

  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const CommitCardImageLeft = styled.div`
  text-align: left;
`;

const CommitCardText = styled.div`
  text-align: right;
`;

const CommitCardRightText = styled.div`
  text-align: left;
`;
const P = styled.p`
  margin-top: 1rem;
  text-align: justify;
`;

const H3 = styled.h3`
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-size: 18px;
`;

const CommitText = styled.div`
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  grid-template-rows: 280px;
  align-items: center;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr !important;
    grid-template-rows: 0.3fr 1fr !important;
  }
`;

const CommitTextImage = styled.div`
  text-align: center;
`;

const CommitCardTextDivider = styled.div`
  width: 100%;
  border-bottom: 1px solid #afafaf;
`;

export const cardFormatImage = graphql`
  fragment cardFormatImage on File {
    childImageSharp {
      gatsbyImageData(width: 72, layout: FIXED)
    }
  }
`;

export const articleFormatImage = graphql`
  fragment articleFormatImage on File {
    childImageSharp {
      gatsbyImageData(width: 250, quality: 100, layout: FIXED)
    }
  }
`;

const rotateItems = [' Desempeño', ' Experiencia', ' Servicio', ' Innovación'];

const Commitment = React.memo(() => {
  const data = useStaticQuery(graphql`
    query {
      visible: file(relativePath: { eq: "commit/Visible-72.png" }) {
        ...cardFormatImage
      }
      termico: file(relativePath: { eq: "commit/Hot-Springs-72.png" }) {
        ...cardFormatImage
      }
      air: file(relativePath: { eq: "commit/Air-Element-Filled-72.png" }) {
        ...cardFormatImage
      }
      fire: file(relativePath: { eq: "commit/Gas-72.png" }) {
        ...cardFormatImage
      }
      hand: file(relativePath: { eq: "commit/Hand-Planting-72.png" }) {
        ...cardFormatImage
      }
      diploma: file(relativePath: { eq: "commit/Diploma-1-72.png" }) {
        ...cardFormatImage
      }
      experience: file(relativePath: { eq: "commit/image_experience_yellow.jpg" }) {
        ...articleFormatImage
      }
      service: file(relativePath: { eq: "commit/image_relationship_yellow.jpg" }) {
        ...articleFormatImage
      }
      innovation: file(relativePath: { eq: "commit/image_innovation_yellw.jpg" }) {
        ...articleFormatImage
      }
    }
  `);

  return (
    <Layout>
      <Seo title="Nuestro Compromiso" />
      <MiniHeader text="Nuestro Compomiso" background={headerBackground} />
      <Container>
        <CommitArticle>
          <div>
            <CommitIntroTitle>
              Los Pilares de Sto -
              <CommitIntroIitleHigh>
                <ReactRotatingText items={rotateItems} />
              </CommitIntroIitleHigh>
            </CommitIntroTitle>
            <CommiIntroP>
              En Sto, estos pilares nos guían para ayudar a los propietarios de inmuebles,
              contratistas y profesionales del diseño a crear edificios más inteligentes. Todos los
              días, nos esforzamos por encontrar la solución adecuada para usted, brindándole un
              servicio y soporte personalizado. Puede confiar en que Sto trabajará con usted como
              socio para proporcionarle la mejor solución de construcción.
            </CommiIntroP>
          </div>
          <CommitTitle>
            <CommitTitleSeparator></CommitTitleSeparator>
            <CommitTitleText>Desempeño</CommitTitleText>
            <CommitTitleSeparator></CommitTitleSeparator>
          </CommitTitle>
          <CommitPerformance>
            <CommitCard>
              <CommitCardText>
                <H3>Estético</H3>
                <CommitCardTextDivider></CommitCardTextDivider>
                <P>
                  Una amplia gama de opciones estéticas de superficie únicas permite que nuestros
                  sistemas de muro sean visualmente agradables y mejoren el atractivo exterior.
                </P>
              </CommitCardText>
              <CommitCardImage>
                <GatsbyImage
                  image={data.visible.childImageSharp.gatsbyImageData}
                  alt="logo-visible"
                />
              </CommitCardImage>
            </CommitCard>
            <CommitCardRight>
              <CommitCardImageLeft>
                <GatsbyImage image={data.fire.childImageSharp.gatsbyImageData} alt="logo-fire" />
              </CommitCardImageLeft>
              <CommitCardRightText>
                <H3>Seguridad Contra Incendios</H3>
                <CommitCardTextDivider></CommitCardTextDivider>
                <P>
                  Invertimos mucho en pruebas de fuego a gran escala para garantizar que nuestros
                  sistemas de muros hayan superado pruebas exhaustivas y funcionen bien en caso de
                  incendio.
                </P>
              </CommitCardRightText>
            </CommitCardRight>
            <CommitCard>
              <CommitCardText>
                <H3>Térmico</H3>
                <CommitCardTextDivider></CommitCardTextDivider>
                <P>
                  Con más de 30 años de experiencia y aplicación práctica de sistemas de muros
                  aislados, nuestros productos cumplen o exceden los requisitos del código de
                  energía y logran un aislamiento térmico óptimo.
                </P>
              </CommitCardText>
              <CommitCardImage>
                <GatsbyImage
                  image={data.termico.childImageSharp.gatsbyImageData}
                  alt="logo-termico"
                />
              </CommitCardImage>
            </CommitCard>
            <CommitCardRight>
              <CommitCardImageLeft>
                <GatsbyImage image={data.hand.childImageSharp.gatsbyImageData} alt="logo-hand" />
              </CommitCardImageLeft>
              <CommitCardRightText>
                <H3>Sostenible</H3>
                <CommitCardTextDivider></CommitCardTextDivider>
                <P>
                  Nuestra impermeabilidad es superior al aire,a las condiciones clímaticas y al
                  rendimiento térmico generando que nuestros sistemas de muros sean ambientalmente
                  sostenibles; reduciendo el uso de energía y la huella de carbono de los edificios.
                </P>
              </CommitCardRightText>
            </CommitCardRight>
            <CommitCard>
              <CommitCardText>
                <H3>Climatico</H3>
                <CommitCardTextDivider></CommitCardTextDivider>
                <P>
                  Una barrera de aire y humedad aplicada con fluidos, que ayuda a sellar la
                  envolvente del edificio, siendo un componente clave en todos nuestros sistemas de
                  paredes de alto rendimiento.
                </P>
              </CommitCardText>
              <CommitCardImage>
                <GatsbyImage image={data.air.childImageSharp.gatsbyImageData} alt="logo-air" />
              </CommitCardImage>
            </CommitCard>
            <CommitCardRight>
              <CommitCardImageLeft>
                <GatsbyImage
                  image={data.diploma.childImageSharp.gatsbyImageData}
                  alt="logo-diploma"
                />
              </CommitCardImageLeft>
              <CommitCardRightText>
                <H3>Garantía</H3>
                <CommitCardTextDivider></CommitCardTextDivider>
                <P>
                  Una colección completa de garantías de sistemas y productos. Las garantías del
                  sistema de hasta 15 años brindan mayor confianza en el rendimiento y la calidad a
                  largo plazo de Sto.
                </P>
              </CommitCardRightText>
            </CommitCardRight>
          </CommitPerformance>
        </CommitArticle>
      </Container>
      <CommitArticleP>
        <CommitTitle>
          <CommitTitleSeparator></CommitTitleSeparator>
          <CommitTitleText>Experiencia</CommitTitleText>
          <CommitTitleSeparator></CommitTitleSeparator>
        </CommitTitle>
        <CommitText>
          <CommitTextImage>
            <GatsbyImage
              image={data.experience.childImageSharp.gatsbyImageData}
              alt="logo-experience"
            />
          </CommitTextImage>
          <dP>
            <p>
              Encontramos las soluciones adecuadas para nuestros clientes. Nuestros experimentados
              asociados están decididos a ayudarlo a encontrar las opciones correctas para cada
              proyecto. Tomamos en cuenta múltiples factores para ayudarlo a crear los edificios más
              eficientes y estéticamente atractivos.
            </p>
            <p>
              Y estamos ansiosos por compartir nuestro amplio conocimiento y apoyo con usted.
              Nuestra mesa de ayuda técnica de Sto le permite hacer preguntas específicas con las
              que necesita ayuda. Después de ingresar una pregunta, uno de nuestros expertos
              responderá dentro de dos días hábiles.
            </p>
          </dP>
        </CommitText>
      </CommitArticleP>
      <CommitArticleP className="commit-article commit-article-padding ">
        <CommitTitle className="commit-title">
          <CommitTitleSeparator className="commit-title-separator"></CommitTitleSeparator>
          <CommitTitleText className="commit-title-text">Servicio</CommitTitleText>
          <CommitTitleSeparator className="commit-title-separator"></CommitTitleSeparator>
        </CommitTitle>
        <CommitText className="commit-text">
          <CommitTextImage className="commit-text_image">
            <GatsbyImage image={data.service.childImageSharp.gatsbyImageData} alt="logo-service" />
          </CommitTextImage>
          <dP>
            <p>
              Siempre estamos ahí para nuestros clientes, brindando soporte y servicios
              personalizados. Junto con nuestros productos, ofrecemos servicios de valor agregado
              para ayudarlo a tomar las mejores decisiones de construcción. Algunos de nuestros
              servicios de valor agregado incluyen:
            </p>
            <ul>
              <li>Revisiones del plan</li>
              <li>Análisis de punto de rocío</li>
              <li>Sto Studio - Servicios de diseño y color</li>
              <li>
                Visitas en sitio para ayudar a resolver los detalles o problemas de la aplicación.
              </li>
            </ul>
          </dP>
        </CommitText>
      </CommitArticleP>
      <CommitArticleP className="commit-article commit-article-padding ">
        <CommitTitle className="commit-title">
          <CommitTitleSeparator className="commit-title-separator"></CommitTitleSeparator>
          <CommitTitleText className="commit-title-text">Innovación</CommitTitleText>
          <CommitTitleSeparator className="commit-title-separator"></CommitTitleSeparator>
        </CommitTitle>
        <CommitText className="commit-text">
          <CommitTextImage className="commit-text_image">
            <GatsbyImage
              image={data.innovation.childImageSharp.gatsbyImageData}
              alt="logo-innovation"
            />
          </CommitTextImage>
          <dP>
            <p>
              A lo largo de la historia de la empresa, hemos establecido un precedente para la
              innovación de productos, la introducción de este tipo de productos que alteran la
              industria como nuestro único StoColor ® Lotusan ® recubrimiento con propiedades
              autolimpiantes y StoGuard ® , nuestra familia de líquido aplicado barreras de aire y
              la humedad.
            </p>
            <p>
              Para ayudar al progreso de la mejora de los sistemas de muros, invertimos mucho en
              Investigación y Desarrollo. Nos enorgullecemos de avanzar en tecnologías cada año y
              mejorar constantemente no solo nuestras ofertas, sino también las ofertas de la
              industria de la construcción en general.
            </p>
          </dP>
        </CommitText>
      </CommitArticleP>
    </Layout>
  );
});

export default Commitment;
